import JsPDF from 'jspdf';
import OutlineAPIService from "../../../../servicehandlers/OutlinesAPIService";
const outlineAPIService = new OutlineAPIService();

export async function printBoardCount() {
  let missingOutlines = false;
  let outlines = [];

  for (let data of this.givenData) {
    if (data.outline) {
      await outlineAPIService.getOutlineByID(data.outline.id, this.$router)
        .then((outline) => {
          outlines.push({
            outline: outline,
            rank: data.rank,
          });
        })
        .catch((error) => {
          this.error_txt = 'Error getting outline by id: ' + error;
          this.openModal('error');
        });
    } else {
      this.warning_txt = 'Be aware that one or more of the competitors is missing an outline';
      this.openModal('warning');
      this.loading = false;
      missingOutlines = true;
    }
  }

  return Promise.all(outlines).then(() => {
    const doc = new JsPDF({ orientation: "portrait" });
    let x = doc.internal.pageSize.getWidth() / 2;
    let y = 10;

    let columns = ['Count', 'Board Size', 'Tester', 'Technique'];

    let boards = {};
    for (let board of this.dropdown_boards.filter(data => data.id !== null)) {
      boards[board.id] = {
        name: board.name,
        count: 0,
        boards: []
      };
    }

    for (const data of outlines) {
      this.setTechniqueNames(data.outline);
      for (let breakObj of data.outline.breaking_techniques_to_outlines) {
        boards[breakObj.board_id].count += breakObj.num_boards;
        boards[breakObj.board_id].boards.push([
          breakObj.num_boards,
          boards[breakObj.board_id].name,
          data.outline.user.first_name + ' ' + data.outline.user.last_name,
          breakObj.techName
        ]);
      }
    }

    let boardList = [];
    let previousTester = "";

    for (let key of Object.keys(boards)) {
      for (let item of boards[key].boards) {
        if (previousTester !== item[2]) {
          // Add a separator row before a new tester
          boardList.push(["", item[1], item[2], ""]);
        }
        boardList.push(item);
        previousTester = item[2];
      }
    }

    doc.setFontSize(16);
    doc.text(this.printName + ' Board Count', x, y, 'center');
    y += 7;

    doc.setFontSize(14);

    if (missingOutlines) {
      doc.setTextColor(255, 0, 0);
      doc.text('Missing one or more Outlines', x, y, 'center');
      doc.setTextColor(0, 0, 0);
      y += 5;
    }

    doc.setFontSize(12);
    doc.text('Board Count:', x, y, 'center');
    y += 5;

    let addX = ((x * 2) - 30) / Object.keys(boards).length;
    x = 30;
    for (let key of Object.keys(boards)) {
      doc.text(boards[key].name + ': ' + boards[key].count, x, y, 'center');
      x += addX;
    }
    y += 5;

    doc.autoTable({
      startY: y,
      head: [columns],
      body: boardList,
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
        fontSize: 10,
        font: 'helvetica',
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 12,
        fontStyle: 'bold',
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
      },
      alternateRowStyles: {
        fillColor: [255, 255, 255],
      },
      didParseCell: function (data) {
        if (data.row.raw[0] === "" && data.row.raw[3] === "") {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.fillColor = [200, 200, 200];
          data.cell.styles.lineWidth = .75;
          data.cell.styles.fontSize = 7;
        }
      },
      pageBreak: 'auto',
      margin: { top: 10 },
    });

    let fileName = this.printName + ' Board Count.pdf';
    doc.save(fileName);
  });
}
