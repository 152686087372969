import { render, staticRenderFns } from "./CompletedRanks.vue?vue&type=template&id=00c05784&scoped=true"
import script from "./CompletedRanks.vue?vue&type=script&lang=js"
export * from "./CompletedRanks.vue?vue&type=script&lang=js"
import style0 from "./CompletedRanks.vue?vue&type=style&index=0&id=00c05784&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00c05784",
  null
  
)

export default component.exports