import CommonAPIService from './CommonHandler';

export class CurriculumCardsAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getCurriculumCards(router) {
    const url = '/api/curriculum_cards';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getCurriculumCardByUserId(userId, router){
    const url = `/api/curriculum_cards/${userId}`
    return this.CommonAPIService.getCall(url, '', router)
  }

  createOrUpdate(cardData, router){
    const url = '/api/curriculum_cards/create/update'
    return this.CommonAPIService.postCall(url, cardData, router)
  }

  deleteByUserID(userId, router){
    const url = `/api/curriculum_cards/byUser/${userId}`
    return this.CommonAPIService.deleteCall(url, '', router)
  }
}

export default CurriculumCardsAPIService;
