<template>
  <div>
    <card v-if="user">
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">{{ user.first_name }} {{user.last_name}} Completed Ranks</h4>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <el-switch
              v-model="hideIncompleteRanks"
              active-text="Hide Incomplete Ranks"
              inactive-text="Show Incomplete Ranks"
              @change="filterRanks"
            >
            </el-switch>
          </div>
<!--          <div class="col-sm-6">
            <div class="row align-items-center">
              <div class="col-sm-8" style="padding-right: 0;"> &lt;!&ndash; Remove padding if needed &ndash;&gt;
                <el-input v-model="search" placeholder="Search" @keyup.native.enter="filteredGenerals"></el-input>
              </div>
              <div class="col-sm-4" style="padding-left: 5px;"> &lt;!&ndash; Minimal padding for spacing &ndash;&gt;
                <el-button type="primary" @click="filterGenerals" icon="">Search</el-button>
              </div>
            </div>
          </div>-->
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4" v-for="rank of filteredRanks" v-bind:key="rank.id">
          <div class="rankCard" :class="completedRanks.some(data => rank.id === data.rank_id) ? 'completed' : ''">
            <div class="row">
              <div class="align-content-center" :class="completedRanks.some(data => rank.id === data.rank_id) ? 'col-sm-4' : 'col-sm-12'">
                <h5>{{rank.name}}</h5>
              </div>
              <div class="col-sm-8" v-if="completedRanks.some(data => rank.id === data.rank_id)">
                <div class="row">
                  <div class="col-sm-6 text-center" :class="!ranks.some(data => data.head_rank_id === rank.id) ? 'col-sm-6' : 'col-sm-12'">
                    <label for="">Completed On</label>
                    <br>
                    {{completedRanks.find(data => rank.id === data.rank_id).createdAt.toLocaleDateString()}}
                  </div>
                  <div class="col-sm-6" v-if="!ranks.some(data => data.head_rank_id === rank.id)">
                    <el-button class="float-right">Certificate</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Button, Switch } from 'element-ui';
import UserAPIService from "../../../../servicehandlers/UsersAPIService";
import RanksAPIService from "../../../../servicehandlers/RanksAPIService";
import MasterPrintDialog from "../../Components/MasterPrintDialog.vue";

const usersAPIService = new UserAPIService()
const ranksAPIService = new RanksAPIService()
export default {
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Switch.name]: Switch,
  },
  name: "CompletedRanks",
  data() {
    return {
      user: null,
      ranks: [],
      filteredRanks: [],
      completedRanks: [],
      modals: {
        error: false,
        success: false,
      },
      error_txt: '',
      success_txt: '',
      hideIncompleteRanks: true,
    }
  },
  async mounted(){
    //console.clear()
    if (this.$route.params.userId){
      await this.getUser(this.$route.params.userId)
    } else {
      await this.getUser(this.$store.getters.userId)
    }
    await this.getCompletedRanks()
    await this.getRanks()
  },
  methods: {
    getUser(id){
      return usersAPIService.getUserById(id, this.$router)
        .then(response => {
          this.user = response
        })
        .catch((e) => {
          this.error_txt = 'Error getting current user: ' + e
          this.openModal('error')
        })
    },
    getRanks(){
      return ranksAPIService.getRanks(this.$router)
        .then(response => {
          for (let rank of response){
            if (response.some(data => data.head_rank_id === rank.id)){
              let subRanks = response.filter(data => data.head_rank_id === rank.id)
              let complete = true
              let maxDate = new Date(0)
              for (let sub of subRanks){
                if (!this.completedRanks.some(data => sub.id === data.rank_id)){
                  complete = false
                } else {
                  let completedAt = this.completedRanks.find(data => sub.id === data.rank_id)
                  if (completedAt.createdAt > maxDate){
                    maxDate = completedAt.createdAt
                  }
                }
              }
              if (complete){
                this.completedRanks.push({rank_id: rank.id, createdAt: maxDate})
              }
            }
          }
          this.ranks = response
          this.filteredRanks = this.ranks
          this.filterRanks()
        })
        .catch((e) => {
          this.error_txt = 'Error getting ranks: ' + e
          this.openModal('error')
        })
    },
    getCompletedRanks(){
      return usersAPIService.getCompletedRanksForUser(this.user.id, this.$router)
        .then(response => {
          this.completedRanks = response
          for (let rank of this.completedRanks){
            rank.createdAt = new Date(rank.createdAt)
          }
        })
        .catch((e) => {
          this.error_txt = 'Error getting completed ranks: ' + e
          this.openModal('error')
        })
    },
    filterRanks(){
      if (this.hideIncompleteRanks){
        this.filteredRanks = this.ranks.filter(rank => this.completedRanks.some(comRank => rank.id === comRank.rank_id))
      } else {
        this.filteredRanks = this.ranks
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
  },
}
</script>

<style scoped>
.rankCard{
  background-color: #f7f7f8;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
}
h5{
  padding: 0;
  margin: 0;
}
.completed{
  border: solid green 1px;
  background-color: #abff8f;
  color: black;
}
</style>
