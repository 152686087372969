<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
    <div slot="header">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-12">
              <h4 class="card-title">Users Table</h4>
              <p class="card-category">
                <el-button type="primary" @click="openModal('new')">Create New User</el-button>
              </p>
              <p class="card-category">

              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row align-items-center">
            <div class="col-sm-8">
              <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterUsers"></el-input>
            </div>
            <div class="col-sm-4" style="padding-left: 5px;">
              <el-button type="primary" @click="filterUsers" icon="">Search</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-table stripe :data="filteredUsers" style="width: 100%" height="65vh" @sort-change="handleSortChange">
      <el-table-column label="Actions" :min-width="200" align="center">
        <template v-slot="scope">
          <el-tooltip effect="dark" content="Proxy as user">
            <el-button type="primary" icon="el-icon-user" @click="proxyLogin(scope.row.id)"></el-button>
          </el-tooltip>
          <el-tooltip effect="dark" content="Change Password">
            <el-button type="info" icon="el-icon-edit" @click="preChangePassword(scope.row.id)"></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="first_name" sortable="custom" label="First Name" :min-width="150" align="center">
        <template v-slot="scope">
          <el-input v-model="scope.row.first_name" @change="createuser(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="last_name" sortable="custom" label="Last Name" :min-width="150" align="center">
        <template v-slot="scope">
          <el-input v-model="scope.row.last_name" @change="createuser(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="username" sortable="custom" label="Username" :min-width="150" align="center">
        <template v-slot="scope">
          {{scope.row.username}}
        </template>
      </el-table-column>
      <el-table-column prop="email" sortable="custom" label="Email" :min-width="150" align="center">
        <template v-slot="scope">
          <el-input v-model="scope.row.email" @change="createuser(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Phone" :min-width="150" align="center">
        <template v-slot="scope">
          <el-input v-model="scope.row.phone" @change="createuser(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Gender" :min-width="150" align="center">
        <template v-slot="scope">
          <el-select v-model="scope.row.gender" placeholder="Gender" style="width: 100%" @change="createuser(scope.row)">
            <el-option v-for="item in genderOptions"
                       :key="item" :value="item" :label="item">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="master_instructor_name" sortable="custom" label="Instructor" :min-width="150" align="center">
        <template v-slot="scope">
          <el-input placeholder="Master Instructor" v-model="scope.row.master_instructor_name" @change="createuser(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="studio_name" sortable="custom" label="Studio Name" :min-width="150" align="center">
        <template v-slot="scope">
          <el-input placeholder="Studio Name" v-model="scope.row.studio_name" @change="createuser(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Rank" :min-width="300" align="center">
        <template v-slot="scope">
          <promotion
            v-if="ranks"
            :next_rank="scope.row.next_rank"
            :user_id="scope.row.id"
            :ranks="ranks"
            :current_rank="scope.row.current_rank"
            @rankError="rankError"
          ></promotion>
        </template>
      </el-table-column>
      <el-table-column label="Belt Rank" :min-width="150" align="center">
        <template v-slot="scope">
          <el-select filterable v-model="scope.row.belt_rank_id" placeholder="Select a Belt Rank"
                     style="width: 100%" @change="createuser(scope.row)">
            <el-option v-for="rank in belt_ranks"
                       :key="rank.id" :value="rank.id" :label="rank.name">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="weight" sortable="custom" label="Weight" :min-width="150" align="center">
        <template v-slot="scope">
          <el-input-number style="width: 100%" v-model="scope.row.weight" @change="createuser(scope.row)"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="Birthday" :min-width="200" align="center">
        <template v-slot="scope">
          <el-date-picker v-model="scope.row.decryptedBirthday" type="date" placeholder="Pick a birthday" @change="updatePoomDan(scope.row)"></el-date-picker>
          <el-select filterable v-model="scope.row.poom_dan_id" placeholder="Select Poom or Dan"
                     style="width: 100%; margin-top: 10px" @change="createuser(scope.row)">
            <el-option v-for="item in poom_dan_options"
                       :key="item.id" :value="item.id" :label="item.name">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="enabled" sortable="custom" label="Enabled" :min-width="150" align="center">
        <template v-slot="scope">
          <el-checkbox v-model="scope.row.enabled" size="large" border>Enabled</el-checkbox>
        </template>
      </el-table-column>
      <el-table-column prop="validated" sortable="custom" label="Validated" :min-width="150" align="center">
        <template v-slot="scope">
          <el-checkbox v-model="scope.row.validated" size="large" border>Validated</el-checkbox>
        </template>
      </el-table-column>
    </el-table>
  </card>

  <el-dialog
    center
    title="Success"
    :close-on-click-modal="false"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>Success: {{ success_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
      </span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Error"
    :close-on-click-modal="false"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
      </span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="New User"
    :close-on-click-modal="false"
    :visible.sync="modals.new">
    <div class="text-center">
      <div class="row">
        <div class="col-3">
          <label>First Name:</label>
          <el-input v-model="newUser.first_name"></el-input>
        </div>
        <div class="col-3">
          <label>Last Name:</label>
          <el-input v-model="newUser.last_name"></el-input>
        </div>
        <div class="col-3">
          <label>Email:</label>
          <el-input v-model="newUser.email"></el-input>
        </div>
        <div class="col-3">
          <label>Phone:</label>
          <el-input v-model="newUser.phone_number"></el-input>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label>Rank:</label>
          <el-select v-model="newUser.rank_id" placeholder="Rank" style="width: 100%">
            <el-option v-for="item in ranks"
                       :key="item.id" :value="item.id" :label="item.name">
            </el-option>
          </el-select>
        </div>
        <div class="col-3">
          <label>Gender:</label>
          <el-select v-model="newUser.gender" placeholder="Gender" style="width: 100%">
            <el-option v-for="item in genderOptions"
                       :key="item" :value="item" :label="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-3">
          <label>Weight:</label>
          <el-input-number style="width: 100%" v-model="newUser.weight"></el-input-number>
        </div>
        <div class="col-3">
          <label>Birthday:</label>
          <el-date-picker v-model="newUser.decryptedBirthday" type="date" placeholder="Pick a birthday" @change="updatePoomDan(newUser, 'new')"></el-date-picker>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label>Belt Rank:</label>
          <el-select filterable v-model="newUser.belt_rank_id" placeholder="Select a Belt Rank" style="width: 100%">
            <el-option v-for="rank in belt_ranks"
                       :key="rank.id" :value="rank.id" :label="rank.name">
            </el-option>
          </el-select>
        </div>
        <div class="col-3">
          <label>Master Instructor:</label>
          <el-input v-model="newUser.master_instructor_name"></el-input>
        </div>
        <div class="col-3">
          <label>Studio:</label>
          <el-input v-model="newUser.studio_name"></el-input>
        </div>
        <div class="col-3">
          <label>Poom/Dan:</label>
          <el-select filterable v-model="newUser.poom_dan_id" placeholder="Select Poom or Dan" style="width: 100%">
            <el-option v-for="item in poom_dan_options"
                       :key="item.id" :value="item.id" :label="item.name">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label>Password:</label>
          <el-input type="password" show-password v-model="newUser.password"></el-input>
        </div>
        <div class="col-6">
          <label>Confirm Password:</label>
          <el-input type="password" show-password v-model="newUser.confirmPassword"></el-input>
        </div>
      </div>
      <br>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="createuser(newUser)" :disabled="newDisabled">Save</el-button>
        <el-button type="danger" @click="closeModal('new')" icon="">Cancel</el-button>
      </span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Change Password"
    :close-on-click-modal="false"
    :visible.sync="modals.password">
    <div class="text-center">
      <p>Password must be at least 8 characters long</p>
      <div class="row">
        <div class="col-6">
          <label>New Password:</label>
          <el-input type="password" show-password v-model="newPassword"></el-input>
        </div>
        <div class="col-6">
          <label>Confirm Password:</label>
          <el-input type="password" show-password v-model="confirmPassword"></el-input>
        </div>
      </div>
      <br>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="changePassword" icon="" :disabled="this.newPassword.length < 8 || this.confirmPassword !== this.newPassword">Save</el-button>
        <el-button type="danger" @click="closeModal('password')" icon="">Cancel</el-button>
      </span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import {phone} from 'phone';
import { Dialog, Option, Select, Button, Checkbox, InputNumber, DatePicker, Table, TableColumn, Tooltip } from 'element-ui';
import { UserAPIService } from 'src/servicehandlers/UsersAPIService';
import { RanksAPIService } from 'src/servicehandlers/RanksAPIService';
import BeltRanksAPIService from "../../../../../servicehandlers/BeltRanksAPIService";
import CommonAPIService from "src/servicehandlers/CommonHandler";
import Promotion from "../../../Components/Promotion.vue";
import crypto from "crypto-js";
import PoomDanAPIService from "../../../../../servicehandlers/PoomDanAPIService";

const apiService = new CommonAPIService();
const usersAPIService = new UserAPIService();
const ranksAPIService = new RanksAPIService();
const beltRanksAPIService = new BeltRanksAPIService();
const poomDanAPIService = new PoomDanAPIService();

export default {
  name: "UserAdmin",
  components: {
    Promotion,
    [Dialog.name]: Dialog,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [InputNumber.name]: InputNumber,
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      modals: {
        error: false,
        success: false,
        password: false,
        new: false,
      },
      selected_user_id: null,
      error_txt: null,
      success_txt: null,
      ranks: [],
      spinning: false,
      belt_ranks: [],
      users: [],
      genderOptions: ['Male', 'Female'],
      passwordVisible: false,
      search: '',
      filteredUsers: [],
      newPassword: '',
      confirmPassword: '',
      newUser: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        phone_number: '',
        gender: '',
        master_instructor_name: '',
        studio_name: '',
        rank_id: null,
        password: '',
        confirmPassword: '',
        address1: '',
        address2: '',
        address3: '',
        validated: false,
        city: '',
        state: '',
        zip: '',
        enabled: true,
        weight: 0,
        decryptedBirthday: '',
        birthday: '',
        poom_dan_id: '',
        belt_rank_id: null,
      },
      poom_dan_options: [],
    }
  },
  async mounted() {
    //console.clear()
    this.spinning = true
    await this.getPoomDan();
    await this.getAllRanks();
    await this.getAllBeltRanks();
    await this.getAllUsers();
  },
  computed: {
    newDisabled(){
      return !(this.newUser.first_name &&
        this.newUser.last_name &&
        this.newUser.email &&
        this.newUser.rank_id &&
        this.newUser.weight &&
        this.newUser.decryptedBirthday &&
        this.newUser.poom_dan_id &&
        this.newUser.password.length >= 8 &&
        this.newUser.confirmPassword === this.newUser.password)
    },
  },
  methods: {
    preChangePassword(userId){
      this.selected_user_id = userId
      this.openModal('password')
    },
    rankError(userId){
      let user = this.users.find(data =>  data.id === userId)
      this.error_txt = user.first_name + ' ' + user.last_name + ' has an invalid rank'
      this.openModal('error')
    },
    getPoomDan(){
      return poomDanAPIService.getPoomDan(this.$router)
        .then(response => {
          this.poom_dan_options = response
          this.poom_dan_options.pop()
        })
    },
    togglePassword(){
      this.passwordVisible = !this.passwordVisible
    },
    changePassword() {
      this.spinning = true;
      const parms = {
        'user_id': this.selected_user_id,
        'password': this.newPassword,
      };
      return usersAPIService.changePassword(parms, this.$router)
      .then(() => {
        this.spinning = false;
        this.success_txt = 'Successfully changed password. ';
        this.openModal('success');
        this.closeModal('password')
      })
      .catch((error) => {
        this.error_txt = 'Error changing password: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    getAllBeltRanks() {
      return beltRanksAPIService.getBeltRanks(this.$router)
      .then((ranks) => {
        for (const rank of ranks) {
          rank.value = rank.id;
          rank.text = rank.name;
        }
        this.belt_ranks = ranks;
      })
      .catch((error) => {
        this.error_txt = 'Error getting belt ranks: ' + error;
        this.openModal('error');
      });
    },
    getAllRanks() {
      return ranksAPIService.getRanks(this.$router)
      .then((ranks) => {
        for (const rank of ranks) {
          rank.value = rank.id;
          rank.text = rank.name;
        }
        this.ranks = ranks;
      })
      .catch((error) => {
        this.error_txt = 'Error getting ranks: ' + error;
        this.openModal('error');
      });
    },
    getAllUsers() {
      return usersAPIService.getUserList(this.$router)
      .then((allUsers) => {
        for (const user of allUsers) {
          user.text = user.first_name + ' ' + user.last_name;
          user.value = user.id;
          if (user.birthday) {
            user.decryptedBirthday = new Date(crypto.AES.decrypt(user.birthday, process.env.VUE_APP_SECRET_KEY).toString(crypto.enc.Utf8))
            this.updatePoomDan(user)
          }
          for (let i = 0; i < this.ranks.length; i++){
            if (this.ranks[i].id === user.rank_id){
              if (i >= 1){
                user.last_rank = this.ranks[i - 1]
              } else {
                user.last_rank = null
              }
              user.current_rank = this.ranks[i]
              if (i < this.ranks.length - 1){
                user.next_rank = this.ranks[i + 1]
                if (user.next_rank.is_sub_rank){
                  let count = 2
                  while (user.next_rank.is_sub_rank){
                    user.next_rank = this.ranks[i + count]
                    count++
                  }
                }
                if (this.ranks.some(data => data.head_rank_id === user.next_rank.id)){
                  user.next_rank = this.ranks[i + 2]
                }
              } else {
                user.next_rank = null
              }
              break
            }
          }
        }
        this.users = allUsers;
        this.filterUsers()
        this.spinning = false
      })
      .catch((error) => {
        this.error_txt = error;
        this.openModal('error');
        this.spinning = false
      });
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
      if (name === 'success'){
        setTimeout(() => this.closeModal(name), 1000)
      }
    },
    updatePoomDan(user, type = null){
      const today = new Date();
      let age = today.getFullYear() - user.decryptedBirthday.getFullYear();
      const monthDifference = today.getMonth() - user.decryptedBirthday.getMonth();
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < user.decryptedBirthday.getDate())) {
        age--;
      }
      let newId
      if (age < 15){
        newId = this.poom_dan_options[0].id
      } else {
        newId = this.poom_dan_options[1].id
      }
      if (newId !== user.poom_dan_id && type !== 'new') {
        user.poom_dan_id = newId
        this.createuser(user)
      }
      user.poom_dan_id = newId
    },
    createuser(user) {
      this.spinning = true;
      if (!user.username) {
        user.username = user.first_name + user.last_name
      }
      if (!user.last_name) {
        this.spinning = false;
        this.error_txt = 'No last name passed in';
        this.openModal('error');
        return;
      }
      if (!user.first_name) {
        this.spinning = false;
        this.error_txt = 'No first name passed in';
        this.openModal('error');
        return;
      }
      if (!user.id && !user.password) {
        this.spinning = false;
        this.error_txt = 'No password was set';
        this.openModal('error');
        return;
      }
      if (!user.id && user.password && user.password.length < 8) {
        this.spinning = false;
        this.error_txt = 'Password length must be set to 8 characters or more';
        this.openModal('error');
        return;
      }
      user.phone = phone(user.phone_number);
      if (!user.rank_id) {
        this.spinning = false;
        this.error_txt = 'Missing rank!';
        this.openModal('error');
        return;
      }
      if (!user.decryptedBirthday) {
        this.spinning = false;
        this.error_txt = 'Missing birthday!';
        this.openModal('error');
        return;
      }

      const parms = {
        username: user.username,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        enabled: user.enabled,
        rank_id: user.rank_id,
        phone: user.phone.phoneNumber,
        master_instructor_name: user.master_instructor_name,
        studio_name: user.studio_name,
        belt_rank_id: user.belt_rank_id,
        weight: user.weight,
        birthday: crypto.AES.encrypt(user.decryptedBirthday.toDateString(), process.env.VUE_APP_SECRET_KEY).toString(),
        poom_dan_id: user.poom_dan_id,
        gender: user.gender,
        validated: user.validated,
      };
      if (user.password) {
        parms.password = user.password;
      }
      if (user.id) {
        parms.id = user.id;
      }
      usersAPIService.createUser(parms, this.$router)
      .then(() => {
        this.spinning = false;
        this.success_txt = 'Successfully updated or created user: ';
        this.openModal('success');
        this.spinning = false;
        if (this.modals.new){
          this.getAllUsers()
          this.closeModal('new')
        }
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error creating user: ' + error;
        this.openModal('error');
      });
    },
    returnUserFullName(user){
      return user.first_name + ' ' + user.last_name
    },
    proxyLogin(id){
      if (id !== null){
        let loggedIn = this.$store.getters.loggedIn;
        let errors = []
        return apiService.proxylogin(id, this.$store.getters.userId, false, this.$router, this.$store, errors)
          .then((response) => {
            if (response && response.success) {
              loggedIn = this.$store.getters.loggedIn;
              if (loggedIn) {
                this.goToHome()
              }
            } else {
              this.error_txt = response.msg;
              this.openModal('error');
            }
          })
          .catch((error) => {
            this.error_txt = error;
            this.openModal('error');
          });
      } else  {
        this.error_txt = "No user Selected";
        this.openModal('error');
      }
    },
    goToHome() {
      // this.rightsArray = this.loginCheckMixin(['page-claim', 'page-firm']);
      // let homePage=this.$store.state.userPreferences.home === 'firms' ? 'FirmsSearch' : 'ClaimsSearch';
      // let homePage=this.$store.state.userPreferences.home === 'claims' ? 'ClaimsSearch' : 'FirmsSearch';
      // homePage is preferred page if user has rights to see, else other search
      // based on all having at least one of these two page rights
      // if (!this.rightsArray.includes('page-firm')) homePage = 'ClaimsSearch';
      // if (!this.rightsArray.includes('page-claim')) homePage = 'FirmsSearch';
      this.$router.push({
        name: 'main'
      });
      location.reload()
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredUsers.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredUsers.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterUsers(){
      try {
        this.filteredUsers = this.users.filter(
          (data) =>
            !this.search ||
            data.first_name.toLowerCase().includes(this.search.toLowerCase()) ||
            data.last_name.toLowerCase().includes(this.search.toLowerCase())
        )
      } catch (e) {
        this.error_txt = 'One or more names missing'
        this.openModal('error')
      }
    },
  }
}
</script>

<style scoped>
i{
  cursor: pointer;
}
</style>
