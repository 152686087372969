import CommonAPIService from './CommonHandler';

export class GeneralsAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  // gets all the generals
  getGenerals(router) {
    const url = '/api/generals';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getGeneralsByRank(rank_id, router) {
    const url = `/api/generals/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  getGeneralsByUnit(unit_id, router) {
    const url = `/api/generals/${unit_id}/unit`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveGeneralsToTechniques(techniques, router) {
    const url = `/api/generals/technique`;
    return this.CommonAPIService.postCall(url, {techniques: techniques}, router);
  }

  getGeneralsByID(generals_id, router) {
    const url = `/api/generals/${generals_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveGeneralsToRank(ranks, router) {
    const url = '/api/generals/rank';
    return this.CommonAPIService.postCall(url, {ranks: ranks}, router);
  }

  addGenerals(generals, router) {
    const url = '/api/generals';
    return this.CommonAPIService.postCall(url, generals, router);
  }

  updateGenerals(general_id, parms, router) {
    const url = `/api/generals/${general_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }
  updateGeneralsToRank(data, rank_id, router) {
    const url = `/api/generals/${rank_id}/torank`;
    return this.CommonAPIService.putCall(url, data, router);
  }
  updateGeneralsToUnit(data, unit_id, router) {
    const url = `/api/generals/${unit_id}/tounit`;
    return this.CommonAPIService.putCall(url, data, router);
  }
  linkGeneralToRank(general_id, rank_id, router) {
    const url = `/api/generals/${general_id}/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  linkGeneralToUnit(general_id, unit_id, router) {
    const url = `/api/generals/${general_id}/${unit_id}/unit`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  deleteGeneralsToRank(general_id, rank_id, router) {
    const url = `/api/generals/${general_id}/${rank_id}/rank`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
  deleteGeneralsToUnit(general_id, unit_id, router) {
    const url = `/api/generals/${general_id}/${unit_id}/unit`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  deleteGenerals(general_id, router) {
    const url = `/api/generals/${general_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  updateTestedOnGeneral(general_id, rank_id, tested, router) {
    const url = `/api/generals/${general_id}/${rank_id}/${tested}/tested/rank`;
    return this.CommonAPIService.putCall(url, null, router);
  }
  updateTestedOnGeneralUnit(general_id, unit_id, tested, router) {
    const url = `/api/generals/${general_id}/${unit_id}/${tested}/tested/unit`;
    return this.CommonAPIService.putCall(url, null, router);
  }
}

export default GeneralsAPIService;
