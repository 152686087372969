<template>
  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <side-bar>
      <mobile-menu></mobile-menu>
      <template slot="links">
        <div style="padding: 4px">
          <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/admin/overview'}"></sidebar-item>
          <sidebar-item v-if="adminLink" :link="{name: 'Curriculum', icon: 'nc-icon nc-app'}">
            <sidebar-item v-if="adminLink" :link="{name: 'General Requirements', icon: 'nc-icon nc-app', path: '/adminmain/generals'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'Techniques (Green Tape)', icon: 'nc-icon nc-app', path: '/adminmain/techniques'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'Step Sparring', icon: 'nc-icon nc-app', path: '/adminmain/stepsparring'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'Poomsae (Black Tape)', icon: 'nc-icon nc-app', path: '/adminmain/poomsae'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'Kicks (Blue Tape)', icon: 'nc-icon nc-app', path: '/adminmain/kicks'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'Self Defense (Brown Tape)', icon: 'nc-icon nc-app', path: '/adminmain/selfdefense'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'Kick Combos (Red Tape)', icon: 'nc-icon nc-app', path: '/adminmain/kick_combos'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'Breaking Techniques', icon: 'nc-icon nc-app', path: '/adminmain/breakingtechniques'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'Default Outlines', icon: 'nc-icon nc-app', path: '/adminmain/outline'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'Terminology (Yellow Tape)', icon: 'nc-icon nc-app', path: '/adminmain/terminology'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'Paperwork (White Tape)', icon: 'nc-icon nc-app', path: '/adminmain/documentation'}"></sidebar-item>
          </sidebar-item>
          <sidebar-item v-if="adminLink" :link="{name: 'Programs & Ranks', icon: 'nc-icon nc-app'}" >
            <sidebar-item v-if="adminLink" :link="{name: 'Programs', icon: 'nc-icon nc-app', path: '/adminmain/programs'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'Ranks', icon: 'nc-icon nc-app', path: '/adminmain/ranks'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'Units', icon: 'nc-icon nc-app', path: '/adminmain/units'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'Rank Assignments', icon: 'nc-icon nc-app', path: '/adminmain/assignments'}"></sidebar-item>
          </sidebar-item>
          <sidebar-item v-if="adminLink" :link="{name: 'User Management', icon: 'nc-icon nc-app'}">
            <sidebar-item v-if="adminLink" :link="{name: 'Group', icon: 'nc-icon nc-app', path: '/adminmain/authgroups'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'Scope', icon: 'nc-icon nc-app', path: '/adminmain/scopes'}"></sidebar-item>
            <sidebar-item v-if="adminLink" :link="{name: 'User', icon: 'nc-icon nc-app', path: '/adminmain/users'}"></sidebar-item>
          </sidebar-item>
          <sidebar-item v-if="adminLink" :link="{name: 'Events Management', icon: 'nc-icon nc-app', path: '/adminmain/eventadmin'}"></sidebar-item>
          <sidebar-item v-if="adminLink" :link="{name: 'Judging Management', icon: 'nc-icon nc-app', path: '/adminmain/judge'}"></sidebar-item>
        </div>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import MobileMenu from './Extra/MobileMenu.vue'
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      MobileMenu,
    },
    data() {
      return{
        judgeLink: false,
        outlineLink: false,
        adminLink: false,
        eventLink: false,
        judgeAdminPage: false,
        judgePage: false,
        userAdminPage: false,
        eventAdminPage: false,
        outlineAdminPage: false,
        studentEventPage: false,
        studentOutlinePage: false,
        studentPrintPage: false,
        scopeAdminPage: false,
        groupAdminPage: false,
        userEventPage: false,
        userOutlinePage: false,
        studentJudgePage: false,
        poomsaeAdminPage: false,
        generalsAdminPage: false,
        techniqueAdminPage: false,
        rankAdminPage: false,
        programAdminPage: false,
        assignmentsPage: false,
        studentRankCheckPage: false,
      };
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },
      getOutlines() {
      },
      initScrollbar() {
        let docClasses = document.body.classList;
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          // if we are on windows OS we activate the perfectScrollbar function
          initScrollbar('main-panel');

          docClasses.add('perfect-scrollbar-on');
        } else {
          docClasses.add('perfect-scrollbar-off');
        }
      }
    },
    mounted() {
      const rights = this.$store.getters.scopes;
      //console.log('Rights: ', rights);
      for (let i = 0; i < rights.length; i += 1) {
        // link rights go here
        if (rights[i].name === 'admin-link') {
          this.adminLink = true;
        }
        if (rights[i].name === 'event-link') {
          this.eventLink = true;
        }
        if (rights[i].name === 'judge-link') {
          this.judgeLink = true;
        }
        if (rights[i].name === 'outline-link') {
          this.outlineLink = true;
        }

        // admin page rights go here
        if (rights[i].name === 'event-admin-page') {
          this.eventAdminPage = true;
        }
        if (rights[i].name === 'group-admin-page') {
          this.groupAdminPage = true;
        }
        if (rights[i].name === 'judge-admin-page') {
          this.judgeAdminPage = true;
        }
        if (rights[i].name === 'scopes-admin-page') {
          this.scopeAdminPage = true;
        }
        if (rights[i].name === 'outline-admin-page') {
          this.outlineAdminPage = true;
        }
        if (rights[i].name === 'user-admin-page') {
          this.userAdminPage = true;
        }
        if (rights[i].name === 'poomsae-admin-page') {
          this.poomsaeAdminPage = true;
        }
        if (rights[i].name === 'generals-admin-page') {
          this.generalsAdminPage = true;
        }
        if (rights[i].name === 'program-admin-page') {
          this.programAdminPage = true;
        }
        if (rights[i].name === 'technique-admin-page') {
          this.techniqueAdminPage = true;
        }
        if (rights[i].name === 'rank-admin-page') {
          this.rankAdminPage = true;
        }
        if (rights[i].name === 'assignments-page') {
          this.assignmentsPage = true;
        }

        // judge rights go here
        if (rights[i].name === 'judge-page') {
          this.judgePage = true;
        }
        if (rights[i].name === 'student-event-page') {
          this.studentEventPage = true;
        }
        if (rights[i].name === 'student-judge-page') {
          this.studentJudgePage = true;
        }
        if (rights[i].name === 'student-outline-page') {
          this.studentOutlinePage = true;
        }
        if (rights[i].name === 'student-print-curriculum') {
          this.studentPrintPage = true;
        }
        if (rights[i].name === 'student-rank-checklist') {
          this.studentRankCheckPage = true;
        }
      }
      this.initScrollbar();
      this.getOutlines();
    }
  }

</script>
